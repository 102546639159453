<template>
  <div>
    <MiniFooter>
      <template v-slot:right>
        Copyright {{ theTime }} <a href="https://12booky.com">{{ appName }}</a> {{ $t('homepage.all_rights_reserved') }}
      </template>
    </MiniFooter>
  </div>
</template>
<script>
import MiniFooter from '../../components/core/footer/MiniFooter'
import { APPNAME } from '../../config/pluginInit'
export default {
  name: 'LayoutFooter',
  components: {
    MiniFooter
  },
  mounted () {
  },
  data () {
    return {
      appName: APPNAME,
      theTime: new Date().getFullYear()
    }
  }
}
</script>
